.image__size {
    width : 40px;
    height : 40px
}

@media (min-width : 768px ){
    .image__size {
        width : 70px;
        height : 70px
    }
    
}