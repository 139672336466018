.logout__label {
    position: relative;
}

.logout__label::before {
    content: '';
    position : absolute;
    bottom : 0;
    height : 1px;
    width : 100%;
    transform : scaleX(0);
    transition: transform ease-in 250ms;
    background-color: #4438cb;
}

.logout__label:hover::before {
    transform : scaleX(1);
}
