.App {
  text-align: center;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main{
  display: flex;
  
}


@keyframes blink {
  0%, 49% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}

.animate-blink {
  animation: blink 1s infinite;
}


/* App.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.modal-close-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background: #ff0000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-close-btn:hover {
  background: #cc0000;
}




.navbar1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #ffffff;
  color: #383838;
  padding: 10px 20px;
  position: fixed;
  height: 50px;
  width: 100%;
  top: 0;
  z-index: 100;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.iconss {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.iconn {
  margin-left: 20px;
  cursor: pointer;
}

.user-info {
  display: flex;
  align-items: center;
}

.profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: auto;
  /* Aligns to the right */
  margin-right: 10px;
  /* Adds some spacing */
  cursor: pointer;
}

.user-details {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  /* Aligns to the right */
  font-size: 14px;
  /* Reduces font size */
  cursor: pointer;
}

.username {
  font-weight: bold;
}

.dropdownn {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.dropbtn {
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.dropdownn-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  left: auto;
}



.dropdownn-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}



.dropdownn-content a:hover {
  background-color: #f1f1f1;
}


.dropdownn:hover .dropdownn-content {
  display: none;
}

.dropdownn:hover .dropbtn {
  background-color: #555;
}

@media (max-width: 600px) {
  .navbar1 {
      flex-direction: column;
      align-items: flex-end;
      /* Aligns items to the right */
  }

  .iconss {
      display: none;
  }

  .profile-photo {
      margin-left: 0;
      margin-right: 5px;
      cursor: pointer;
  }

  .user-info {
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      cursor: pointer;
  }

  .user-details {
      margin-left: 0;
      cursor: pointer;
  }

  .dropbtn {
      background-color: #333;
      color: white;
      border: none;
      cursor: pointer;
      font-size: 16px;
      /* Added */
      margin-left: auto;
      /* Aligns to the right */
      margin-right: 10px;
      /* Adds some spacing */
  }
}

.user-detail-container {
  margin-top: 60px;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.card {
  /* background: #d0f6ff; */
  background: #09B3C1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  width: 100%;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-header {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  /* margin-bottom: 20px; */
}

.card-header img {
  width: 250px;
  height: 250px;
  /* border-radius: 50%; */
  margin-right: 50px;
}

.card-details {
  display: flex;
  flex-direction: column;
}

.user-detail {
  margin-bottom: 10px;
}

/* .qr-code {
  margin-top: 20px;
}

.qr-code img {
  width: 200px;
  height: 200px;
} */

@media (max-width: 600px) {
  .card-header {
      flex-direction: column;
      align-items: center;
  }

  .card-header img {
      margin-right: 0;
      margin-bottom: 20px;
  }
}


.pulse {
  width: 22.4px;
  height: 22.4px;
  border-radius: 50%;
  background: #474bff;
  box-shadow: 0 0 0 0 rgba(71,75,255,0.5);
  animation: pulse-c6zlskmd 1s infinite;
}

@keyframes pulse-c6zlskmd {
  100% {
     box-shadow: 0 0 0 33.6px #0000;
  }
}

 /* Custom scrollbar styles 
 ::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  background: #6366F1; 
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background: #4f46e5; 
}

*/



#content {
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Smooth fade in */
}


.loaded #content {
  opacity: 1; /* Fade in */
}

.preloading {
  width: 100vw;
  height : 100svh;
  overflow : hidden;
}

.apexcharts-tooltip.apexcharts-theme-light
    {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }