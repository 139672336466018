input {
    background-color: transparent;
}

.password__forgot-label {
    position: relative;
}

.password__forgot-label::before {
    content : '';
    height : 1px;
    width : 100%;
    position : absolute;
    bottom : 0;
    transform: scaleX(0);
    transition: transform ease-in 250ms;
    background-color: red;
}

.password__forgot-label:hover::before {
    transform: scaleX(1);

}

.login__bg-position {
    background-position: center;
}

.login__wrapper {
    background-color: rgba(255, 255, 255);
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

@media (min-width : 990px ){
.login__bg-position {
    background-position: -15em 0;

}

.login__wrapper {
    background-color: rgba(255, 255, 255, 0.1);
    width : fit-content
}
}